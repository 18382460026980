<template>
    <!----Top Artist Section---->
            <div class="ms_top_artist" style="margin-bottom: 12vh;">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="ms_heading">
                                <h1>الشعراء</h1>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-4 col-xl-2" v-for="(art, index) in artist" :key="index">
                            <div class="ms_rcnt_box marger_bottom30 ">
                                <div class="ms_rcnt_box_img" @click="getFeed(art)">
                                    <img v-lazy="art.img" alt="" class="img-fluid">
                                    <div class="ms_main_overlay">
                                <div class="ms_box_overlay"></div>
                                <div class="ms_play_icon" @click="getFeed(art)">
                                    <img src="../../assets/images/svg/play.svg" alt="">
                                </div>
                                <!-- <div class="ovrly_text_div">
                                    <span class="ovrly_text1"><a href="#">romantic</a></span>
                                </div> -->
                            </div>
                            <div class="ms_box_overlay_on">
                                <div class="ovrly_text_div">
                                    <span class="ovrly_text1"><a href="#">{{ art.cat_name }}</a></span>
                                </div>
                            </div>
                                </div>
                                <div class="ms_rcnt_box_text pointer">
                                    
                                    <h3><a @click="getFeed(art)">{{ art.cat_name }}</a></h3>
                                    <h3><a @click="getFeed(art)">{{ art.name }}</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next';
export default {
  name: 'AppPoets',
  async setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      const artist = ref([]); 

       try {
        await HTTP.get(`GetAllArtists.php`).then((res) => {
            artist.value = res.data.Artists; 
        });
      } catch (err) {
        console.log(err);
      }
  
      const getFeed = (art) => {
        // cookie.setCookie('cat_id', publish.cat_id);
        // if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
          router.push({ name: "Contents", params: { content_id: art.id, cat_name: art.cat_name } });
        // } else {
        //   cookie.setCookie('content_id', publish.id);
        //   router.push({ name: "Subscribes" });
        // }
      };
      return { artist, getFeed };
   },
}
</script>

<style>

</style>